const HeaderText = {
  en: {
    home: 'Home',
    blog: 'Blog',
    contact: 'Contact',
  },
  pt: {
    home: 'Início',
    blog: 'Blog',
    contact: 'Contato',
  },
};

export default HeaderText;
